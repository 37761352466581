const constantsItem = {
 url: 'https://tallen.tech/api/public/api/v1',

  //local host url

  //url: 'http://127.0.0.1:8000/api/v1',

  /*
    adminUrl: 'http://10.0.2.2:8000/api/admin',
    domain_name: 'http://10.0.2.2:8000',
    */

  //Login API constants
  LOGIN_BY: 'manual',

  DEVICE_TYPE: 'web',

  DEVICE_TOKEN: '123456',

  banner: 'ca-app-pub-2908427537917173/2051888314',
  intertitial: 'ca-app-pub-2908427537917173/1992463974',
  rewarded: 'ca-app-pub-2908427537917173/2381107092',
  native: 'ca-app-pub-2908427537917173/3528621511',
};

export default constantsItem;



