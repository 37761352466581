import '../../css/main.css';
//import img from '../../images/home.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import img2 from '../../images/testimonial/6.png';
import tony from '../../images/testimonial/tonny.jpeg';

import evelyn from '../../images/evelyn.png';

import ben from '../../images/testimonial/3.png';

import winnie from '../../images/testimonial/5.png';
import log from '../../images/log.png';
import testi from '../../images/testimonial/3.png';
import testi1 from '../../images/testimonial/6.png';
import sharon from '../../images/testimonial/2.png';
import quotation from '../../images/quotation.svg';

const ButtonGroup = ({next, previous, goToSlide, ...rest}) => {
  const {carouselState: {currentSlide}} = rest;
  return (
    <div className="carousel-button-group" style={{display: 'flex'}}>

      <div className="testimonial-btn-main">
        <i
          class="fas fa-arrow-circle-left"
          style={{fontSize: 30, color: '#BAEFF2', cursor: 'pointer'}}
          onClick={() => previous ()}
        />
        {' '}{' '}
        <div style={{paddingLeft: 10}}>
          {' '}
          <i
            class="fas fa-arrow-circle-right"
            onClick={() => next ()}
            style={{fontSize: 30, color: '#BAEFF2', cursor: 'pointer'}}
          />
        </div>
      </div>
    </div>
  );
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 2,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};

const Testimonial = () => {
  return (
    <div>
      <div className="testimonial">
        <div style={{width: '50%'}}>
          <div className="testimonia--slider">
            <Carousel
              responsive={responsive}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            >
              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  TALLEN's app maintenance services have given us peace of mind knowing that our digital presence is in good hands. Their proactive approach to identifying and fixing potential issues before they become bigger problems has saved us time and money. We are thrilled with the services we've received from TALLEN.

                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={tony}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Tonny</h5>
                    <div className="cont-title">
                      Founder of 360tv inc
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card2">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We have been extremely impressed with the web maintenance services provided by TALLEN. Their team is knowledgeable and always goes above and beyond to ensure that our website and applications are running smoothly. We highly recommend them to anyone looking for reliable web and app maintenance services
                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={evelyn}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Evelyn</h5>
                    <div className="cont-title">
                      CEO, Farmsell App
                    </div>

                  </div>

                </div>

              </div>

              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  TALLEN's mobile app development services were top-notch from start to finish. Their team's ability to understand our unique needs and requirements resulted in a custom solution that met all of our expectations. We are thrilled with the end result and highly recommend TALLEN to anyone looking for professional mobile app development services.
                  {' '}
                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={ben}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Lydia</h5>
                    <div className="cont-title">
                      Founder/Children Witness international.
                    </div>

                  </div>

                </div>

              </div>

              <div className="testimonial-card2">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We were blown away by the quality of the mobile app developed by TALLEN. Their team was knowledgeable, efficient, and always available to answer any questions we had. The end result was exactly what we were looking for and we couldn't be happier. We highly recommend TALLEN for professional mobile app development services

                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={winnie}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Rahman Khan Ibra</h5>
                    <div className="cont-title">
                      Founder/Director Fashion Dress up
                    </div>

                  </div>

                </div>

              </div>

              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We hired TALLEN to redesign our outdated website and they exceeded our expectations in every way. The new website is modern, user-friendly, and has already helped us increase our online conversions. TALLEN's attention to detail and commitment to customer satisfaction sets them apart from other web development companies

                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={log}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Johnson Okongo</h5>
                    <div className="cont-title">
                      Director of PIXEL MArketing Solutions
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card2">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  TALLEN has been an invaluable partner for our company's web development needs. Their team is highly skilled, efficient, and always goes above and beyond to deliver outstanding results. We highly recommend TALLEN for anyone looking for a reliable web development company

                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={sharon}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Sharon Abwooli</h5>
                    <div className="cont-title">
                      IT manager of Marketing Corp
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We worked with TALLEN on a redesign of our mobile app and were blown away by the results. The team was able to take our ideas and turn them into a stunning, user-friendly design that has received rave reviews from our customers. TALLEN truly has a knack for creating amazing UI/UX designs and we highly recommend them
                </div>
                <div
                  className="testimonial-profile-content"
                  style={{marginTop: -24}}
                >
                  <div className="profile-testimonial">
                    <img
                      src={testi1}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Daniel</h5>
                    <div className="cont-title">
                      Head of Marketing, Beltext International
                    </div>

                  </div>

                </div>

              </div>

              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We worked with TALLEN on a redesign of our mobile app and were blown away by the results. The team was able to take our ideas and turn them into a stunning, user-friendly design that has received rave reviews from our customers. TALLEN truly has a knack for creating amazing UI/UX designs and we highly recommend them
                </div>
                <div
                  className="testimonial-profile-content"
                  style={{marginTop: -24}}
                >
                  <div className="profile-testimonial">
                    <img
                      src={img2}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">John</h5>
                    <div className="cont-title">
                      IT manager of Marketing Corp
                    </div>

                  </div>

                </div>

              </div>

              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We worked with TALLEN on a redesign of our mobile app and were blown away by the results. The team was able to take our ideas and turn them into a stunning, user-friendly design that has received rave reviews from our customers. TALLEN truly has a knack for creating amazing UI/UX designs and we highly recommend them
                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={testi}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">John</h5>
                    <div className="cont-title">
                      IT manager of Marketing Corp
                    </div>

                  </div>

                </div>

              </div>

            </Carousel>
          </div>
        </div>
        <div className="testimonial-content">
          <span style={{color: '#02A2AC'}}>TESTIMONIES</span>

          <div className="testimonial-header">

            What our

            <span style={{color: '#F7901F'}}> Clients</span>
            {' '}
            say about our services
          </div>

          <div className="testimonial-txt">
            Our company excels in delivering exceptional work that surpasses client expectations. Our team of skilled professionals closely collaborates with clients to ensure timely delivery of high-quality results.
          </div>

        </div>
      </div>

      <div className="testimonial-mobile">

        <div className="testimonial-content">
          <span style={{color: '#02A2AC'}} className="testimony-to-center">
            TESTIMONIES
          </span>

          <div className="testimonial-header">

            What our

            <span style={{color: '#F7901F'}}> Clients</span>
            {' '}
            say about our services
          </div>

          <div className="testimonial-paragraph">
            Our company excels in delivering exceptional work that surpasses client expectations. Our team of skilled professionals closely collaborates with clients to ensure timely delivery of high-quality results.
          </div>

          <div>
            <Carousel
              responsive={responsive}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            >
              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We have been extremely impressed with the web maintenance services provided by TALLEN. Their team is knowledgeable and always goes above and beyond to ensure that our website and applications are running smoothly. We highly recommend them to anyone looking for reliable web and app maintenance services
                </div>
                <div
                  className="testimonial-profile-content"
                  style={{marginTop: -20}}
                >
                  <div className="profile-testimonial">
                    <img
                      src={tony}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Tony</h5>
                    <div className="cont-title">
                      Founder of 360tv inc
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card2">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We worked with TALLEN on a redesign of our mobile app and were blown away by the results. The team was able to take our ideas and turn them into a stunning, user-friendly design that has received rave reviews from our customers. TALLEN truly has a knack for creating amazing UI/UX designs and we highly recommend them
                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={evelyn}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Evelyn</h5>
                    <div className="cont-title">
                      CEO farmsell
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  TALLEN's mobile app development services were top-notch from start to finish. Their team's ability to understand our unique needs and requirements resulted in a custom solution that met all of our expectations. We are thrilled with the end result and highly recommend TALLEN to anyone looking for professional mobile app development services.

                </div>
                <div
                  className="testimonial-profile-content"
                  style={{marginTop: -30}}
                >
                  <div className="profile-testimonial">
                    <img
                      src={ben}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Lydia</h5>
                    <div className="cont-title">
                      Founder/Children Witness international.
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card2">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We were blown away by the quality of the mobile app developed by TALLEN. Their team was knowledgeable, efficient, and always available to answer any questions we had. The end result was exactly what we were looking for and we couldn't be happier. We highly recommend TALLEN for professional mobile app development services

                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={winnie}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Rahman Khan Ibra</h5>
                    <div className="cont-title">
                      Founder/Director Fashion Dress up
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  TALLEN has been an invaluable partner for our company's web development needs. Their team is highly skilled, efficient, and always goes above and beyond to deliver outstanding results. We highly recommend TALLEN for anyone looking for a reliable web development company
                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={log}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Johnson Okongo</h5>
                    <div className="cont-title">
                      IT manager of Marketing Corp
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card2">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  TALLEN has been an invaluable partner for our company's web development needs. Their team is highly skilled, efficient, and always goes above and beyond to deliver outstanding results.
                  We highly recommend TALLEN for anyone looking for a reliable web development company

                </div>
                <div className="testimonial-profile-content">
                  <div className="profile-testimonial">
                    <img
                      src={sharon}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">Sharon Abwooli</h5>
                    <div className="cont-title">
                      IT manager of Marketing Corp
                    </div>

                  </div>

                </div>

              </div>
              <div className="testimonial-card">
                <img src={quotation} className="quotation" alt="slide show" />
                <div className="quotation-content">
                  We worked with TALLEN on a redesign of our mobile app and were blown away by the results. The team was able to take our ideas and turn them into a stunning, user-friendly design that has received rave reviews from our customers. TALLEN truly has a knack for creating amazing UI/UX designs and we highly recommend them
                </div>
                <div
                  className="testimonial-profile-content"
                  style={{marginTop: -20}}
                >
                  <div className="profile-testimonial">
                    <img
                      src={img2}
                      alt="slide show"
                      className="profile-testimonial"
                    />
                  </div>
                  <div className="cont-testimonial">
                    <h5 className="cont-header">John</h5>
                    <div className="cont-title">
                      IT manager of Marketing Corp
                    </div>

                  </div>

                </div>

              </div>

            </Carousel>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Testimonial;
